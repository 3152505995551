.custom-checkbox input[type="checkbox"] + label::before {
  border: 2px solid #66cc23;
  /* Set the border color as desired */
  color: #fff !important; }

/* Style the checkmark */
.custom-checkbox input[type="checkbox"]:checked + label::before {
  color: #fff !important;
  background-color: #66cc23;
  /* Set the background color of the checkmark */
  border-color: #66cc23;
  /* Set the border color of the checkmark */ }

/* Change the color of the checkmark's inner circle */
.custom-checkbox input[type="checkbox"]:checked + label::after {
  background-color: #66cc23;
  /* Set the background color of the checkmark's inner circle */
  color: #fff !important; }

.remove-bom {
  width: 400px !important; }

.hosemaker-modal {
  width: 1380px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important; }

.other-components-modal {
  width: 900px !important;
  min-height: 448.1px !important;
  max-height: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important;
  overflow: scroll; }

.results {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 370px; }

.job-management ::-webkit-scrollbar {
  width: 10px !important; }

.job-management ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.job-management ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.job-management .error input {
  border-color: #cc181e !important; }

.job-management .error {
  border-color: #cc181e !important; }

.job-management .select-error {
  border-color: #cc181e !important; }

.job-management div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .job-management .column {
    padding-left: 0px !important; } }

.job-management .ui.labeled.icon.button > .icon,
.job-management .ui.labeled.icon.buttons > .button > .icon {
  color: #66cc23 !important; }

.job-management .job-list-section {
  height: 750px; }
  .job-management .job-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 10px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .job-management .job-list-section hr {
        margin-left: 20px; } }
  .job-management .job-list-section .job-header {
    height: 45px;
    padding-left: 10px;
    padding-right: 1rem; }
    @media (min-width: 1025px) {
      .job-management .job-list-section .job-header {
        padding-left: 20px; } }
  .job-management .job-list-section .job-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .job-management .job-list-section .job-status button {
      padding: 8px;
      margin-top: 5px; }
    .job-management .job-list-section .job-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
    @media (min-width: 1025px) {
      .job-management .job-list-section .job-status {
        padding-left: 20px; } }
  .job-management .job-list-section .job-list {
    height: 600px; }
    .job-management .job-list-section .job-list .job-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .job-management .job-list-section .job-list .job-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .job-management .job-list-section .job-list .job-list-scroll {
          padding-left: 20px; } }
      .job-management .job-list-section .job-list .job-list-scroll .active {
        box-shadow: 0 4px 8px 0 #66cc23, 0 4px 8px 0 #66cc23 !important; }
      .job-management .job-list-section .job-list .job-list-scroll .job-card {
        margin-top: 10px;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }
        .job-management .job-list-section .job-list .job-list-scroll .job-card .content {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          padding-left: 10px !important; }
          @media (max-width: 1024px) {
            .job-management .job-list-section .job-list .job-list-scroll .job-card .content {
              padding-right: 0px; } }
        .job-management .job-list-section .job-list .job-list-scroll .job-card .description {
          font-size: 14px !important; }
        .job-management .job-list-section .job-list .job-list-scroll .job-card .job-state {
          min-height: 117px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .job-management .job-list-section .job-list .job-list-scroll .job-card .job-state .job-state-text {
            transform: rotate(-90deg);
            white-space: nowrap;
            font-size: 11px;
            text-align: center; }

.job-management .job-form {
  height: 750px; }
  @media (max-width: 767px) {
    .job-management .job-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .job-management .job-form {
      padding-left: 10px !important; } }
  .job-management .job-form .job-actions {
    height: 45px;
    padding-right: 1rem; }
    .job-management .job-form .job-actions button {
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px; }
      @media (max-width: 1300px) {
        .job-management .job-form .job-actions button {
          font-size: 1vw !important; } }
    .job-management .job-form .job-actions .ui.card {
      margin: 0px !important;
      margin-left: 3px !important; }
    .job-management .job-form .job-actions .active-status {
      background-color: #66cc23;
      color: #fff; }
  .job-management .job-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }
  .job-management .job-form .job-form-content {
    padding-right: 10px;
    height: 700px;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: scroll; }
    .job-management .job-form .job-form-content .arrow-steps .step {
      font-size: 14px;
      text-align: center;
      color: #0c0d0e;
      cursor: default;
      margin: 0 1px 0 0;
      padding: 10px 0px 10px 0px;
      float: left;
      position: relative;
      background-color: #e3e6ea;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      min-width: 98px; }
    .job-management .job-form .job-form-content .arrow-steps .step a {
      color: #0c0d0e;
      text-decoration: none;
      cursor: pointer; }
    .job-management .job-form .job-form-content .arrow-steps .step:after,
    .job-management .job-form .job-form-content .arrow-steps .step:before {
      content: "";
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
      border-left: 17px solid #e3e6ea;
      z-index: 2; }
    .job-management .job-form .job-form-content .arrow-steps .step:before {
      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0; }
    .job-management .job-form .job-form-content .arrow-steps .step:first-child:before {
      border: none; }
    .job-management .job-form .job-form-content .arrow-steps .step:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .job-management .job-form .job-form-content .arrow-steps .step span {
      position: relative; }
    .job-management .job-form .job-form-content *.arrow-steps .step.done span:before {
      opacity: 1;
      content: "";
      position: absolute;
      top: -2px;
      left: -10px;
      font-size: 11px;
      line-height: 21px; }
    .job-management .job-form .job-form-content .arrow-steps .step.current {
      color: #fff;
      background-color: #66cc23; }
    .job-management .job-form .job-form-content .arrow-steps .step.current a {
      color: #fff;
      text-decoration: none; }
    .job-management .job-form .job-form-content .arrow-steps .step.current:after {
      border-left: 17px solid #66cc23; }
    .job-management .job-form .job-form-content .arrow-steps .step.done {
      color: #0c0d0e;
      background-color: #ced4db; }
    .job-management .job-form .job-form-content .arrow-steps .step.done a {
      color: #0c0d0e;
      text-decoration: none; }
    .job-management .job-form .job-form-content .arrow-steps .step.done:after {
      border-left: 17px solid #ced4db; }
    .job-management .job-form .job-form-content .arrow-steps .step.archived {
      color: #ced4db;
      background-color: #dd4b39; }
    .job-management .job-form .job-form-content .arrow-steps .step.archived:before {
      border: none !important; }
    .job-management .job-form .job-form-content .arrow-steps .step.archived a {
      color: #fff;
      text-decoration: none; }
    .job-management .job-form .job-form-content .arrow-steps .step.archived:after {
      border-left: 17px solid #dd4b39; }

.job-management .job-info {
  padding-right: 10px;
  height: 780px;
  overflow-x: hidden;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    .job-management .job-info {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .job-management .job-info {
      padding-left: 10px !important; } }
  .job-management .job-info .active-status {
    background-color: #66cc23;
    color: #fff; }

.job-management .job-card {
  margin-top: 10px;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative; }

.jobbing-search-box,
.jobbing-search-box-nosearch {
  width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 1rem; }

.jobbing-search-box .ui.input,
.jobbing-search-box-nosearch .ui.input {
  width: 100%; }

.jobbing-search-box .ui.input input {
  border-radius: 5px 5px 0px 0px;
  font-weight: bold; }

.jobbing-search-box-nosearch .ui.input input {
  border-radius: 5px;
  font-weight: bold; }

.jobbing-search-box .results,
.jobbing-search-box-nosearch .results {
  width: 100%;
  font-size: small; }

.job-search-type {
  width: auto;
  color: gray;
  padding-left: 18px;
  padding-top: 5px;
  border-radius: 0px 0px 5px 5px;
  font-size: small;
  background-color: #e3e6ea; }

.ui.selection.dropdown > .search.icon {
  margin-top: -14.5px !important; }
