.hose-maker {
  width: 1340px !important;
  margin: auto; }
  .hose-maker ::-webkit-scrollbar {
    width: 10px !important; }
  .hose-maker ::-webkit-scrollbar-thumb {
    background: #66cc23; }
  .hose-maker div[disabled] {
    pointer-events: none;
    opacity: 0.7; }
  .hose-maker .select-error {
    border-color: #cc181e !important; }
  .hose-maker .bomImage {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .hose-maker span {
    line-height: 0px; }
  .hose-maker .hose-actions {
    height: 45px;
    padding-right: 1rem; }
    .hose-maker .hose-actions button {
      background-color: #979fa8;
      color: #fff;
      padding-left: 22px;
      padding-right: 22px; }
    .hose-maker .hose-actions .active-status {
      background-color: #66cc23;
      color: #fff; }
  .hose-maker .arrow-steps .step {
    font-size: 13px;
    text-align: center;
    color: #0c0d0e;
    cursor: default;
    margin: 0 1px 0 0;
    padding: 10px 0px 10px 0px;
    float: left;
    position: relative;
    background-color: #e3e6ea;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 90px; }
  .hose-maker .arrow-steps .step a {
    color: #0c0d0e;
    text-decoration: none;
    cursor: pointer; }
  .hose-maker .arrow-steps .step:after,
  .hose-maker .arrow-steps .step:before {
    content: "";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 17px solid #e3e6ea;
    z-index: 2; }
  .hose-maker .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0; }
  .hose-maker .arrow-steps .step:first-child:before {
    border: none; }
  .hose-maker .arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .hose-maker .arrow-steps .step span {
    position: relative; }
  .hose-maker *.arrow-steps .step.done span:before {
    opacity: 1;
    content: "";
    position: absolute;
    top: -2px;
    left: -10px;
    font-size: 11px;
    line-height: 21px; }
  .hose-maker .arrow-steps .step.current {
    color: #fff;
    background-color: #66cc23; }
  .hose-maker .arrow-steps .step.current a {
    color: #fff;
    text-decoration: none; }
  .hose-maker .arrow-steps .step.current:after {
    border-left: 17px solid #66cc23; }
  .hose-maker .arrow-steps .step.done {
    color: #0c0d0e;
    background-color: #ced4db; }
  .hose-maker .arrow-steps .step.done a {
    color: #0c0d0e;
    text-decoration: none; }
  .hose-maker .arrow-steps .step.done:after {
    border-left: 17px solid #ced4db; }
  .hose-maker .arrow-steps .step.disabled {
    color: #0c0d0e;
    background-color: lightgray; }
  .hose-maker .arrow-steps .step.disabled a {
    color: #0c0d0e;
    text-decoration: none;
    cursor: default; }
  .hose-maker .arrow-steps .step.disabled:after {
    border-left: 17px solid lightgray; }
  .hose-maker .hose-maker-container {
    width: 100%;
    height: 601px; }
  .hose-maker .hose-maker-scroll {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
  .hose-maker .product-card .product-header {
    max-width: calc(100% - 100px);
    word-wrap: break-word;
    margin-top: -32px !important;
    font-size: 0.8em; }
    .hose-maker .product-card .product-header .product-header-stock {
      font-size: 1.2em;
      padding-right: 5px;
      color: green; }
    .hose-maker .product-card .product-header .card-meta {
      font-weight: normal;
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.4); }
  @media screen and (max-width: 480px) {
    .hose-maker .product-card .card-description .product-info-grid {
      padding-right: 0px; } }
  .hose-maker .product-card .card-description .product-info-grid .add-card-column {
    margin-top: 10px;
    border-left: 1px solid #eee; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .hose-maker .product-card .card-description .product-info-grid .add-card-column {
        margin: 14px auto; } }
    @media (min-width: 481px) and (max-width: 748px) {
      .hose-maker .product-card .card-description .product-info-grid .add-card-column {
        margin: 14px auto; } }
    @media screen and (max-width: 480px) {
      .hose-maker .product-card .card-description .product-info-grid .add-card-column {
        margin: 14px auto; } }
    .hose-maker .product-card .card-description .product-info-grid .add-card-column .cart-button {
      margin-top: 44px; }
      @media (min-width: 768px) and (max-width: 1024px) {
        .hose-maker .product-card .card-description .product-info-grid .add-card-column .cart-button {
          margin-top: 27px; } }
    .hose-maker .product-card .card-description .product-info-grid .add-card-column .quantity-form {
      margin-top: 5px; }
      .hose-maker .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-label {
        margin-top: 6px;
        text-align: center;
        font-weight: bold; }
      .hose-maker .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-width {
        margin-top: 5px;
        width: 100%; }
    .hose-maker .product-card .card-description .product-info-grid .add-card-column .box-saving-text {
      color: green;
      font-weight: bold; }
  .hose-maker .item-list {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0; }
    .hose-maker .item-list li {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px; }
    .hose-maker .item-list .item {
      width: 360px;
      padding: 20px;
      background-color: #66cc23;
      margin: auto;
      color: #fff;
      font-size: 12px; }
  .hose-maker .job-status {
    padding-bottom: 10px;
    font-size: 8px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px; }
    .hose-maker .job-status button {
      padding: 12px;
      margin-top: 5px;
      width: 120px;
      margin-left: 4px;
      margin-right: 4px; }
    .hose-maker .job-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
