hr {
  height: 1px !important;
  background-color: #ccc;
  border: none;
  margin-left: 0px;
  margin-right: 1rem; }

.my-customers ::-webkit-scrollbar {
  width: 10px !important; }

.my-customers ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.my-customers ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.my-customers div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .my-customers .column {
    padding-left: 0px !important; } }

.my-customers .customer-input {
  width: 100%; }

.my-customers .customer-input > input {
  width: 100%;
  background: transparent !important;
  padding: 0px;
  border: none;
  border-bottom: 2px solid #000 !important;
  border-radius: 0px;
  font-size: 1.71428571rem; }

.my-customers .transfer-list-section {
  height: 730px; }
  .my-customers .transfer-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 0px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .my-customers .transfer-list-section hr {
        margin-left: 20px; } }
  .my-customers .transfer-list-section .transfer-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .my-customers .transfer-list-section .transfer-status button {
      padding: 8px;
      margin-top: 5px; }
    .my-customers .transfer-list-section .transfer-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
    @media (min-width: 1025px) {
      .my-customers .transfer-list-section .transfer-status {
        padding-left: 20px; } }
  .my-customers .transfer-list-section .transfer-list {
    height: 600px; }
    .my-customers .transfer-list-section .transfer-list .inventory-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .my-customers .transfer-list-section .transfer-list .inventory-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .my-customers .transfer-list-section .transfer-list .inventory-list-scroll {
          padding-left: 20px; } }
      .my-customers .transfer-list-section .transfer-list .inventory-list-scroll .active {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px, 0px 6px 4px 0px #66cc23 !important; }
      .my-customers .transfer-list-section .transfer-list .inventory-list-scroll .order-card {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }

.my-customers .customer-form-content {
  padding-right: 10px;
  height: 630px;
  padding-bottom: 50px;
  overflow-x: hidden;
  overflow-y: scroll; }
  .my-customers .customer-form-content .ui.checkbox input.hidden + label {
    padding-left: 22px !important; }

.my-customers .transfer-form {
  height: 750px; }
  @media (max-width: 767px) {
    .my-customers .transfer-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .my-customers .transfer-form {
      padding-left: 10px !important; } }
  .my-customers .transfer-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }

.edit-state {
  background-color: #ffad00 !important;
  color: #fff !important;
  border-radius: 6px; }

.active-status {
  background-color: #66cc23;
  color: #fff; }

.default-status {
  background-color: #878787;
  color: #fff; }

.customer-search-box,
.customer-search-box-nosearch {
  width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 1rem; }

.customer-search-box .ui.input,
.customer-search-box-nosearch .ui.input {
  width: 100%; }

.customer-search-box .ui.input input {
  border-radius: 5px 5px 0px 0px;
  font-weight: bold; }

.customer-search-box-nosearch .ui.input input {
  border-radius: 5px;
  font-weight: bold; }

.customer-search-box .results,
.customer-search-box-nosearch .results {
  width: 100%;
  font-size: small; }

.customer-search-type {
  width: auto;
  color: gray;
  padding-left: 18px;
  padding-top: 5px;
  border-radius: 0px 0px 5px 5px;
  font-size: small;
  background-color: #e3e6ea; }
